import React from 'react'
import {BrowserRouter as Router, Routes,Route} from 'react-router-dom'
import ContactPage from './components/ContactPage';
import MobileNavbar from './components/MobileNavbar';
import Hero from './components/Hero';
import Footer from "./components/Footer";
import Services from './components/Services';
import Brands from './components/Brands';
import About from './components/About';
import ServiceDetails from './pages/services/ServiceDetails';

function App() {
  return (
    <Router>
    <div>
        <ContactPage />
        <MobileNavbar />
     
      <Routes>
        <Route path='/' element={
        <>
        <Hero/>
        <About/>
        <Services/>
       </>
        }/>
       
      <Route path='/services/:serviceName' element={<ServiceDetails/>}/>
      </Routes>
          <Brands/>
          <Footer />
      </div>
    </Router>
  );
}
export default App;
   
      
         
 
      
 
  
      

