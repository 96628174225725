import React, { useState } from "react";
import { IoWater } from "react-icons/io5";
import { IoIosArrowDown, IoIosMenu, IoIosClose } from "react-icons/io";
import { navItems } from "../constants";
import { Services } from "../constants";
import { useNavigate, useLocation } from "react-router-dom";

const MobileNavbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const scrollToSection = (sectionId)=>{
    const section = document.getElementById(sectionId)
    if(section){
      section.scrollIntoView({behavior:'smooth'})
    }
  }

  const handleAboutClick = ()=>{
    if(location.pathname !== "/"){
      navigate("/", {state:{scrollTo:"about"}})
    } else{
      scrollToSection('about')
    }
  }

  const GetQuote = () =>{
    const phoneNumber = '+254759522983'
    const message = 'Hello, I would like to place an order'
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`

    window.open(url, '_blank')
   }

  return (
    <div className="bg-black-100 h-[70px] flex items-center justify-between relative">
      <div 
        onClick={()=> navigate('/')}
      className="flex items-center ml-5 hover:cursor-pointer">
        <IoWater className="h-[70px] w-[50px] text-blue-500" />
        <span className="text-lg font-bold flex items-center justify-center">
          HATUA
        </span>
      </div>

      {/* Mobile Menu Icon */}
      <div className="md:hidden flex items-center justify-between mr-10">
        {isMobileMenuOpen ? (
          <IoIosClose
            className="h-8 w-8 text-blue-500 cursor-pointer"
            onClick={toggleMobileMenu}
          />
        ) : (
          <IoIosMenu
            className="h-8 w-8 text-blue-500 cursor-pointer"
            onClick={toggleMobileMenu}
          />
        )}
      </div>

      {/* Desktop Nav */}
      <div className="md:flex md:space-x-5 font-semibold hidden">
        <div 
        onClick={()=> handleAboutClick()}
        
        className="hover:text-white hover:bg-[#F08080] rounded-md p-2 cursor-pointer">
          About
        </div>

        <div
          onMouseEnter={() => setIsDropdownOpen(true)}
          onMouseLeave={() => setIsDropdownOpen(false)}
          className="relative"
        >
          <div className="flex space-x-2 items-center hover:text-white hover:bg-[#F08080] rounded-md p-2 cursor-pointer">
            <div
             onClick={()=> navigate('/')}
            >Our Services</div>
            
            <span>
              <IoIosArrowDown />
            </span>
          </div>

          {isDropdownOpen && (
            <div className="absolute bg-white rounded-md shadow-md p-3 z-10 w-auto">
              <div className="flex flex-col w-[200px] items-center justify-center">
                {Services.map((service,key)=>(

                <div 
                onClick={()=> navigate(`services/${service.route}`, {state:{service}})}
                
                key={key}
                className="hover:bg-[#F08080] p-2 cursor-pointer hover:rounded-lg hover:text-white">
                  {service.name}
                </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div 
        onClick={()=> GetQuote()}
        className="hover:text-white hover:bg-[#F08080] rounded-md p-2 cursor-pointer">
          Get a free quote
        </div>
        <div className="hover:text-white hover:bg-[#F08080] rounded-md p-2 cursor-pointer">
          Projects
        </div>
      </div>
               
               
      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="absolute z-10 bg-white rounded-2xl shadow-lg w-full top-16 px-5 opacity-90">
          {navItems.map((navItem, index) => (
            <ul
              key={index}
              className="text-sm uppercase cursor-pointer text-lg text-[#F08080] hover:text-gray-900 my-7"
            >
              {navItem.label === "about"?(
                <li onClick={handleAboutClick} >{navItem.label}</li>):
               navItem.label === "Our Services"?(
                <>
                <li
                   className="flex items-center justify-between"
                   onClick={()=>
                    setIsDropdownOpen((prevState)=>!prevState)
                   }>
                    {navItem.label}
                    <IoIosArrowDown/>
                   </li>
                   {isDropdownOpen && (
                    <div className="pl-4">
                      {Services.map((service,key)=>(
                        <div
                           key={key}
                           onClick={()=>
                            navigate(`services/${service.route}`,{
                              state:{service}
                            })
                           }
                           className="hover:bg-[#F08080] p-2 cursor-pointer hover:rounded-lg hover:text-white">
                            {service.name}
                           </div>
                      ))}
                    </div>
                   )}
                </>
               ) :navItem.label === "Get a free quote" ? (
                <li onClick={GetQuote}>{navItem.label}</li>
               ):(
                <a href={navItem.href}>{navItem.label}</a>
               )}
            </ul>
          ))}
        </div>
      )}
    </div>
  );
};

export default MobileNavbar;
                
               
                
              


