import React,{useEffect} from 'react';
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { PiShieldStarThin } from "react-icons/pi";

import { useLocation } from 'react-router-dom';



const About = () => {
  const location = useLocation()

  useEffect (()=>{
    if(location.state?.scrollTo){
      const section = document.getElementById(location.state.scrollTo)
        if(section){
          section.scrollIntoView({behavior:'smooth'})
        }    
    }
  },[location])
 
  return (
    <div id='about' className='bg-black  text-white px-5 py-5 relative'>
      <div className='text-center tracking-wider font-black text-lg py-2'>
        The Hatua <span className='text-[#F08080]'>Narrative</span>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-4'>
        <div className='flex space-x-2 items-center justify-center'>
          <div className='bg-gray-100 rounded-md text-black flex flex-col space-y-1 px-2 py-3'>
            <PiShieldStarThin className='items-start mx-2' size={35} fill='#F08080'  />
            <div className='font-bold'>Humble Beginnings</div>
            <div className='font-normal leading-relaxed'>
              Hatua Kenya began with a mission to address the pressing need for sustainable water access
              in underserved communities. With a single drilling rig, they started providing borehole solutions,
              empowering rural areas to become self-sufficient.
            </div>
          </div>
          <FaArrowLeft size={60} className='hidden md:block'/>
        </div>
        <div className='hidden md:block'> </div>

        <div className='hidden md:block'></div>
        <div className='flex space-x-2 items-center justify-center'>
          <FaArrowRight size={50} className='hidden md:block'/>
          <div className='bg-gray-100 rounded-md text-black flex flex-col space-y-2 px-2 py-3'>
            <PiShieldStarThin className='items-start mx-2' size={35} fill='#F08080'  />
            <div className='font-bold'>Expanding Horizons</div>
            <div className='font-normal leading-relaxed'>
              Over time, Hatua embraced solar technology, offering clean, renewable energy to complement
              their water projects, paving the way for a brighter, sustainable future.
            </div>
          </div>
        </div>

        <div className='flex space-x-2 items-center justify-center'>
          <div className='bg-gray-100 rounded-md text-black flex flex-col space-y-1 px-2 py-3'>
            <PiShieldStarThin className='items-start mx-2' size={35} fill='#F08080' />
            <div className='font-bold'>African Footprint</div>
            <div className='font-normal leading-relaxed'>
              Today, Hatua Kenya has grown beyond borders, serving communities in South Sudan and other
              African nations, leaving a lasting legacy of innovation and impact.
            </div>
          </div>
          <FaArrowLeft size={60} className='hidden md:block' />
        </div>
        <div className='hidden md:block'></div>
      </div>
    </div>
  );
};

export default About;
