import React from "react";
import { FaBoreHole } from "react-icons/fa6"; 
import { LiaIndustrySolid } from "react-icons/lia";
import { WiDaySprinkle } from "react-icons/wi";
import { FaOilWell } from "react-icons/fa6";
import { FaArrowUpFromWaterPump } from "react-icons/fa6";
import { MdSolarPower } from "react-icons/md";
import { Services } from "../constants/index";
import { GiStoneTower } from "react-icons/gi";


import { useNavigate } from "react-router-dom";

function ServicesComponent() {

  const navigate = useNavigate()
  const iconMap = {
    FaBoreHole: <FaBoreHole size={50} />,
    LiaIndustrySolid: <LiaIndustrySolid size={70}/>,
    WiDaySprinkle: <WiDaySprinkle size={70} fill="orange"/>,
    FaOilWell: <FaOilWell size={70} fill="brown"/>,
    FaArrowUpFromWaterPump: <FaArrowUpFromWaterPump size={70} fill="pink"/>,
    MdSolarPower: <MdSolarPower size={70} fill="orange"/>,
    GiStoneTower: <GiStoneTower size={70} fill="gray"/>,
  };

  return (
    <div className="bg-black " id="services">
    <div className="bg-[#202C45] py-10 px-5 text-white mx-5 rounded-lg">

      <div className="font-semibold text-center flex flex-col space-y-2">
        <div 
        className="text-lg md:text-4xl mb-[10px]">Services</div>
        <span className="h-[2px] w-[50px] md:w-[100px] bg-white mx-auto"></span>
        <div className="mx-auto leading-relaxed font-normal md:font-medium md:text-lg">
          Our commitment to quality forms the foundation of our competitive
          advantage, earning the trust of our customers.
          <span className="block mt-2">
            For us, customer satisfaction is our top priority!
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-12 px-10 py-10 gap-y-20 gap-x-4">
        {Services.map((service, index) => (
          <div
            key={index}
            className="md:col-span-4 flex flex-col items-center justify-center text-center space-y-2 cursor-pointer"
            onClick={()=> navigate(`/services/${service.route}`, {state:{service}})}
          >
            {iconMap[service.icon]}
            <div className="font-black text-2xl">{service.name}</div>
            <div className="text-start tracking-wide">{service.description}</div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default ServicesComponent;
