import { IoLocation } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { MdPhone } from "react-icons/md";
import FloatingWhatsUp from "../addOns/FloatingWhatsUp";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-10 px-5 md:px-10">
      <FloatingWhatsUp className="text-black" />

      {/* Divider */}
      <div className="bg-white mt-2 w-full h-[1px]" />

      {/* Footer Content */}
      <div className="mt-10 grid gap-y-10 md:grid-cols-3 md:gap-x-10">
        {/* Services Section */}
        <div>
          <h3 className="pb-3 text-lg font-semibold">SERVICES</h3>
          <div className="bg-[#6c3ca3] w-10 h-1 rounded mb-4" />
          <ul className="space-y-2 text-sm">
            <li className="cursor-pointer hover:text-[#F08080]">Industrial Borehole</li>
            <li className="cursor-pointer hover:text-[#F08080]">Irrigation Borehole</li>
            <li className="cursor-pointer hover:text-[#F08080]">Well Hand Pumps</li>
            <li className="cursor-pointer hover:text-[#F08080]">Submersible Pumps</li>
            <li className="cursor-pointer hover:text-[#F08080]">Solar Installation</li>
            <li className="cursor-pointer hover:text-[#F08080]">Tower Construction</li>
          </ul>
        </div>

        {/* Explore Section */}
        <div>
          <h3 className="pb-3 text-lg font-semibold">EXPLORE</h3>
          <div className="bg-[#6c3ca3] w-10 h-1 rounded mb-4" />
          <ul className="space-y-2 text-sm">
            <li className="cursor-pointer hover:text-[#F08080]">About Us</li>
            <li className="cursor-pointer hover:text-[#F08080]">Get Free Quote</li>
            <li className="cursor-pointer hover:text-[#F08080]">Projects</li>
          </ul>
        </div>

        {/* Contact Section */}
        <div>
          <h3 className="pb-3 text-lg font-semibold">CONTACT US</h3>
          <div className="bg-[#6c3ca3] w-10 h-1 rounded mb-4" />
          <ul className="space-y-3 text-sm">
            <li className="flex items-center gap-x-2">
              <IoLocation className="text-[#F08080]" size={20} />
              <p>Premium Bld, Eastern Bypass Utawala</p>
            </li>
            <li className="flex items-center gap-x-2">
              <IoIosMail className="text-[#F08080]" size={20} />
              <p>hatuakenya@gmail.com</p>
            </li>
            <li className="flex items-center gap-x-2">
              <MdPhone className="text-[#F08080]" size={20} />
              <p>+254 729 001 057</p>
            </li>
          </ul>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-10 text-center text-sm text-gray-400">
        © {new Date().getFullYear()} HATUA KENYA. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
