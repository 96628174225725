import React from "react"
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
const ContactPage =() =>{
   
    return(
        <div>
         
                <div className="bg-gradient-to-r from-[#121B2C] to-[#202C45] h-[70px] transition-opacity duration-300 flex items-center px-2">
                <div className="text-white flex justify-between mx-5 text-xs md:text-lg w-full md:flex md:items-center md:space-x-20 md:w-auto">
                       <div className="flex items-center justify-start py-3">
                          <AiOutlineMail className="inline-block mr-2 text-[#F08080]"/>
                          <span>hatuakenya@gmail.com</span>
                       </div>
                       <div className="flex items-center justify-start ">
                          <BsTelephoneFill className="inline-block mr-2 text-[#F08080]"/>
                          <span>+254 729 001 057</span>
                       </div>
                      
                </div>
    
            </div>

       
        </div>
    )
}

export default ContactPage