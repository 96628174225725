import React from 'react'
import borehole2 from '../assets/images/borehole2.jpg'

function Hero() {
  return (
    <div>
        <div className='relative min-h-screen overflow-hidden'>
            <img
            className='absolute inset-0 w-full h-full object-cover'
            src={borehole2}
            alt=' truck digging up a borehole'/>
            <div className='absolute inset-0 w-full h-full bg-gradient-to-t from-black/80 via-black/70 to-black/40'></div>
            <div className='absolute inset-0 flex flex-col justify-center px-5 text-left'>
              
                <div className=' font-semibold text-3xl leading-relaxed text-[#F08080]'>Welcome</div>
                <div className='text-white font-bold text-5xl md:text-7xl tracking-tight  mb-5 opacity-40'>We are HatuaEnergy</div>
                <div className='text-white font-medium text-2xl mb-5 opacity-50 tracking-wide'>
                               "Reliable Borehole Drilling. Sustainable Water Solutions. Empowering Communities."
                </div>

             

            </div>
        </div>
      
    </div>
  )
}

export default Hero
