import kericho_logo from '../assets/images/Kericho.jpeg';
import kisii_logo from '../assets/images/kisii.png';
import narok_logo from '../assets/images/narok.webp';
import nationalIrigation_logo from "../assets/images/nationalIrrigation.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // for carousel to function properly
import { Carousel } from 'react-responsive-carousel';

const Brands = () => {
  return (
    <div className='bg-black h-[100px] md:h-[150px]'>
      <div className='bg-white mx-5 rounded-md'>
        <div className='flex justify-center py-1'>
          <div className='text-3xl font-bold text-[#F08080] text-center border-b-2 border-[#F08080]'>
            Our Clients
          </div>
        </div>

        {/* desktop carousel */}
        <div className='hidden md:block'>
          <Carousel
            infiniteLoop={true}
            autoPlay={true}
            interval={3000}
            showIndicators={false}
            transitionTime={500}
            useKeyboardArrows={true}
            swipeable={true}
            emulateTouch={true}
          >
            <div className='flex items-center justify-evenly'>
              <div>
                <img
                  alt='kericho logo'
                  className='max-h-20 max-w-[150px] object-contain'
                  src={kericho_logo}
                />
              </div>

              <div>
                <img
                  alt='kisii logo'
                  className='max-h-20 max-w-[150px] object-contain'
                  src={kisii_logo}
                />
              </div>
            </div>

            <div className='flex items-center justify-evenly'>
              <div>
                <img
                  alt='narok logo'
                  className='max-h-20 max-w-[150px] object-contain'
                  src={narok_logo}
                />
              </div>

              <div>
                <img
                  alt='nationalIrrigation logo'
                  className='max-h-20 max-w-[150px] object-contain'
                  src={nationalIrigation_logo}
                />
              </div>
            </div>
          </Carousel>
        </div>

        {/* mobile carousel */}
        <div className='md:hidden block'>
          <Carousel
            infiniteLoop={true}
            autoPlay={true}
            interval={3000}
            showIndicators={false}
            transitionTime={500}
            useKeyboardArrows={true}
            swipeable={true}
            emulateTouch={true}
          >
            <div className='flex items-center justify-evenly'>
              <div>
                <img
                  alt='kericho logo'
                  className='max-h-16 max-w-[100px] object-contain'
                  src={kericho_logo}
                />
              </div>

              <div>
                <img
                  alt='kisii logo'
                  className='max-h-16 max-w-[100px] object-contain'
                  src={kisii_logo}
                />
              </div>
            </div>

            <div className='flex items-center justify-evenly'>
              <div>
                <img
                  alt='narok logo'
                  className='max-h-16 max-w-[100px] object-contain'
                  src={narok_logo}
                />
              </div>

              <div>
                <img
                  alt='nationalIrrigation logo'
                  className='max-h-16 max-w-[100px] object-contain'
                  src={nationalIrigation_logo}
                />
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Brands;
